.share-popin {
  width: 100%;
  max-width: 600px;
  height: auto;
  padding: 40px;

  text-align: center;

  h2 {
    margin: 20px 0 40px 0;
    strong {
      color: $colorFuchsia;
    }
  }

  .share-icons {
    button {
      margin: 5px;
    }
  }
}