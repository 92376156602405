
.errors-popin {
  width: 100%;
  clear: both;
  font-size: 12px;

  &:before {
    width: 100%;
    display: block;
    content: "";
    clear: both;
  }

  p {
    font-size: 1rem;
    font-weight: bold;
    margin-top: 20px;
    strong {
      color: $colorBrown;
    }
  }

  ul {
    margin: 0;
    padding: 0 0 0 15px;
    li {

    }
  }

}