.areas {
  display: flex;
  max-width: 350px;
  justify-content: space-between;

  margin-top: 30px;

  .area {
    text-align: center;
    .img {
      width: 100px;
      height: 100px;
      background: transparent no-repeat 50% 50%;
      background-size: 100%;
      &.img-north {
        background-image: url('/img/north.png');
      }
      &.img-center {
        background-image: url('/img/center.png');
      }
      &.img-south {
        background-image: url('/img/south.png');
      }
    }
  }

}