@import "../../../scss/variables";

//
//@keyframes sendIconAnimation {
//  0% {
//    transform: translateX(0);
//  }
//  100% {
//    transform: translateX(35px);
//  }
//}

@keyframes h1Animation {
  0% {
    //transform: translateY(-80%) translateX(0) scale(2.5);
    transform: translateY(-80%) translateX(0) scale(1);
    opacity: 0;
  }
  100% {
    transform: translateY(0) translateX(0);
    opacity: 1;
  }
}
@keyframes logoAnimation {
  0% {
    //transform: translateY(-50%) translateX(0) scale(1.5);
    transform: translateY(-50%) translateX(0) scale(1);
    opacity: 0;
  }
  100% {
    transform: translateY(0) translateX(0);
    opacity: 1;
  }
}
@keyframes txtAnimation {
  0% {
    //transform: translateY(-50%) translateX(0) scale(1.5);
    transform: translateY(-50%) translateX(0) scale(1);
    opacity: 0;
  }
  100% {
    transform: translateY(0) translateX(0);
    opacity: 1;
  }
}
@keyframes bgAnimation {
  0% {
    background-color: rgba($colorDarkGray, 1);
  }
  100% {
    background-color: rgba($colorDarkGray, 0.8);
  }
}



.section.home {
  width: 100%;
  //height: 100vh;
  color: $colorBlack;
  text-align: center;

  padding: 40px 20px;

  background: $colorBeige no-repeat center bottom;
  background-size: cover;

  box-shadow: 0px 0px 15px -8px $colorDarkGray;

  user-select: none;

  display: flex;
  align-items: center;
  >div {
    margin: 0 auto;
    max-width: 100%;
  }

  .home-logo {
    margin-bottom: 3%;

    animation-name: txtAnimation;
    animation-duration: 1.5s;
    animation-delay: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    transform: translateY(-50%) scale(1);
    opacity: 0;

    img {
      max-width: 150px;
      height: auto;
      @media (max-width: 400px) {
        max-width: 100px;
      }
      @media (max-height: 640px) {
        max-width: 60px;
      }
    }
  }



  h1 {
    font-family: $font-family-serif;
    text-transform: uppercase;
    font-size: 7vw;
    line-height: 1.1;
    user-select: none;
    animation-name: h1Animation;
    animation-duration: 1.5s;
    animation-delay: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    transform: translateY(-80%) scale(2);
    opacity: 0;
    strong {
      color: $colorFuchsia;
    }
    @media (min-width: 640px) {
      font-size: 3.5vw;
      line-height: 1.4;
      br.xs {
        display: none;
      }
    }
  }

  .home-img {
    animation-name: txtAnimation;
    animation-duration: 1.5s;
    animation-delay: 1.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    transform: translateY(-50%) scale(1);
    opacity: 0;
    margin: 20px auto;
    img {
      max-width: 100%;
      height: auto;
      @media (max-height: 640px) {
        max-width: 300px;
      }
    }
  }

  p.txt {
    font-size: 1rem;
    padding: 0 5%;
    max-width: 85%;
    margin: 0 auto 30px auto;
    animation-name: txtAnimation;
    animation-duration: 1.5s;
    animation-delay: 2.25s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    transform: translateY(-50%) translateX(0) scale(1.5);
    opacity: 0;
    strong, a {
      color: $colorBrown;
      text-decoration: none;
    }
    a:hover {
      text-decoration: underline;
    }
    @media (min-width: 641px) {
      font-size: 1rem;
      max-width: 65%;
    }
    @media (min-width: 1440px) {
      font-size: 1vw;
    }
  }

  .footnote {
    position: absolute;
    left: 10px;
    bottom: 10px;
    opacity: 0.5;
    @media (min-width: 641px) {
      left: 30px;
      bottom: 30px;
    }
    font-size: 0.75rem;
    a {
      color: inherit;
      &:hover {
        text-decoration: underline;
      }
    }
  }




}

