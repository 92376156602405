@import "../../../../scss/variables";

@keyframes progressBar {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.ages {
  .age {
    margin: 0 0 10px 0;
    &:nth-child(odd) {
      .count {
        background-color: $colorLightGreen;
      }
    }
    &:nth-child(even) {
      .count {
        background-color: $colorLightBlue;
      }
    }
  }
  .bar {
    width: 100%;
    max-width: 500px;
    position: relative;
    height: 30px;
    overflow: hidden;
    border-radius: 5px;
    .count {
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 5px;
      height: 100%;
      transform: translateY(-100%);
      animation-name: progressBar;
      animation-duration: 0.75s;
      animation-delay: 0s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
    }
    .label {
      display: flex;
      justify-content: space-between;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      span {
        color: $colorWhite;
        padding: 5px 10px;
        text-transform: uppercase;
        &:first-child {
          text-align: left;
        }
        &:last-child {
          text-align: right;
        }
      }
    }
  }
}