@import "../../../scss/variables";

@keyframes formAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.section.formSection {

  padding: 0 20px;
  width: 100%;
  //height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  background: $colorBeige no-repeat center bottom;
  background-size: cover;


  @media (max-width: 767px) {
    padding: 40px 20px;
  }

  div.form {

    position: relative;

    &.hidden {
      visibility: hidden;
    }


    flex-wrap: wrap;

    .form-intro {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
      p {
        margin: 0;
      }
      strong {
        color: $colorBrown;
      }
    }



    .txt {
      p {
        margin: 10px 0 0 0;
        text-align: center;
        strong {
          color: $colorBrown;
        }
      }
    }

    .spacer {
      height: 30px;
    }



    >div.col {
      text-align: left;
      padding: 0 30px;

      @media (max-width: 480px) {
        padding: 0 10px;
      }
    }
    @media (min-width: 641px) {
      display: flex;
      max-width: 800px;
      >div.col {
        width: 50%;
        padding: 20px 40px;
      }
    }

    //@media(max-width: 767px) {
    //  input[type='text'],
    //  input[type='number'],
    //  select,
    //  option,
    //  textarea {
    //    font-size: 16px !important;
    //  }
    //}

    .row {
      display: flex;
      >div {
        width: 50%;
        &:first-child {
          padding-right: 10px;
        }
        &:last-child {
          padding-left: 10px;
        }
      }
    }

    margin: 4% auto;

    animation-name: formAnimation;
    animation-duration: 1.5s;
    animation-delay: 3.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    opacity: 0;

    .balloon {
      transform: translateX(0) translateY(0);
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      margin-top: 10px;
      @media (min-width: 641px) {
        margin-top: 0;
      }
    }
    textarea {
      width: 100%;
      height: 50px;
      @media (min-width: 641px) {
        height: 150px;
      }
      background: transparent;
      border: none;
      outline: none;
      resize: none;
      color: $colorDarkGray;
      font-size: 1rem;
    }


    input, select {
      border: none;
      height: 34px;
      outline: none;
      margin: 2px 0;
      font-size: 1rem;
      padding: 5px;
      width: 100%;
      @media (min-width: 641px) {
        margin: 10px;
      }
      &[type=number] {
        -webkit-appearance: none;
        -moz-appearance: textfield;
      }

      background-color: transparent;
      color: $colorDarkGray;
      border-bottom: 1px solid rgba($colorDarkGray, 0.7);

      //background-color: $colorBeige;
      //color: $colorDarkGray;

      @media(max-width: 767px) {
        font-size: 16px;
        padding: 0;
        line-height: 1;
      }

    }
    option {
      background-color: $colorBeige;
      color: $colorDarkGray;
      padding: 5px;
    }
    input {
      padding: 5px 10px;
      @media(max-width: 767px) {
        padding: 0;
      }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    ::-webkit-input-placeholder { /* Edge */
      color: $colorDarkGray;
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $colorDarkGray;
    }
    ::placeholder {
      color: $colorDarkGray;
    }

    label {
      height: 0;
      position: relative;
      top: -10px;
      font-size: 9px;
      margin-left: 20px;
      transition: all 0.3s;
      margin-bottom: -10px;
      visibility: hidden;
      display: none;
    }

    select.field-topic {

    }
    button.topics-help {
      position: absolute;
      left: -15px;
      top: 13px;
      border: none;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      background-color: $colorDarkGray;
      text-align: center;
      line-height: 24px;
      color: $colorWhite;
      transition: all 0.2s;
      user-select: none;
      &:hover {
        background-color: $colorFuchsia;
      }
      @media (max-width: 640px) {
        top: 7px;
        left: -24px;
      }
    }


    input.blank + label, select.blank + label, input.filled + label, select.filled + label {

    }
    @media (min-width: 768px) {
      input.filled + label, select.filled + label {
        visibility: visible;
        transform: translateY(-40px);
        opacity: 0.5;
        display: block;
      }
      input.blank + label, select.blank + label {
        visibility: visible;
        transform: translateY(-5px);
        opacity: 0;
        display: block;
      }
      label[for=field-txt] {
        display: none;
      }
    }

    &.hidden {
      label { visibility: hidden !important; }
    }


    div.with-error {
      input, select {
        border-bottom-color: $colorFuchsia;
      }
    }


    .emoji-btn {
      border: none;
      background-color: transparent;
    }

    .form-btn {
      background-color: $colorFuchsia;
      border-radius: 15px;
      padding: 2px 40px 2px 30px;
      border: none;
      outline: none;
      color: $colorWhite;
      position: relative;
      height: 34px;
      line-height: 26px;
      float: right;
      margin: 10px 0 0 0;
      &:after {
        content: "";
        position: absolute;
        right: 5px;
        top: 50%;
        margin-top: -10px;
        width: 19px;
        height: 23px;
        background: transparent url('/img/send.svg') no-repeat 50% 50%;
        background-size: 100%;
        clear: both;
      }
      transition: opacity 0.2s;
      &:hover {
        opacity: 0.8;
      }

    }

    .field-toggle {
      transition: opacity 0.3s;
    }
    .field-visible {
      opacity: 1;
    }
    .field-hidden {
      opacity: 0;
      input, select {
        pointer-events: none;
      }
    }

  }



  .emoji-picker {
    position: absolute;
    z-index: 2;
    input {
      color: $colorWhite !important;
    }
    section {
      height: auto !important;
      min-height: auto !important;
    }
  }

  .emoji-mart-bar {
    display: none;
  }

}