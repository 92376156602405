.info-boxes {

  display: flex;
  margin: 6% auto;
  flex-wrap: wrap;

  >div {
    width: 50%;
    text-align: center;

    &:first-child {
      text-align: right;
      padding: 0 10px 0 0;
      //a {
      //  padding-top: 12%;
      //  @media (max-width: 640px) {
      //    padding-top: 20px;
      //  }
      //}
    }
    &:last-child {
      padding: 0 0 0 10px;
      text-align: left;
    }

    @media (max-width: 640px) {
      width: 100%;
      padding: 10px 0 !important;
      text-align: center !important;
      max-width: 300px;
      margin: 0 auto;
    }

  }
  a, button {
    border: none;
    display: inline-flex;
    align-items: center;
    align-content: center;
    text-align: center;
    padding: 20px;
    margin: 0 auto;
    background-color: $colorFuchsia;
    border-radius: 20px;
    color: $colorWhite;
    width: 300px;
    height: 200px;
    font-size: 20px;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
    //img {
    //  position: absolute;
    //  left: 50%;
    //  top: -50%;
    //  transform: translateX(-50%);
    //  width: 60%;
    //  height: auto;
    //  z-index: 1;
    //}
    transition: all 0.2s;
    @media (min-width: 768px) {
      &:hover {
        text-decoration: none;
        box-shadow: 0px 0px 20px -5px $colorFuchsia;
        transform: scale(1.05);
      }
    }

    @media (max-width: 767px) {
      width: 100%;
      font-size: 16px;
      img {
        width: 40%;
        top: -25%
      }
    }
    @media (max-width: 640px) {
      height: auto;
      img {
        display: none;
      }
    }
    @media(max-width: 767px) and (max-height: 767px) {
      font-size: 12px;
      display: inline-block;
    }



  }

}