footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 10px 20px;
  //background-color: rgba($colorDarkGray, 0.1);
  background: transparent no-repeat center top;
  box-shadow: 0px 0px 10px -8px $colorDarkGray;
  >div {
    small {
      display: block;
      text-align: left;
    }
    @media (min-width: 641px) {
      display: flex;
      justify-content: space-between;
      max-width: 980px;
      margin: 0 auto;
      small {
        display: inline;
      }
    }
    @media (min-width: 641px) and (max-width: 1150px) {
      small {
        &:last-child {
          text-align: right;
          padding-right: 60px;
        }
      }
    }
  }
  a {
    color: $colorFuchsia;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      color: $colorFuchsia;
    }
  }
}