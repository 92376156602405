
.popin-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: calc(100% - 40px);
  height: 500px;
  max-height: calc(100% - 60px);
  max-width: 400px;

  background-color: $colorLightGray;

  padding-top: 0;

  z-index: 999;
  box-shadow: 0px 0px 55px -5px $colorDarkGray;
  border-radius: 10px;
  opacity: 1;
  transition: all 0.3s;
  overflow: hidden;

  &.animate-in {
    transform: translateX(-50%) translateY(-100%);
    opacity: 0;
  }

  .popin-body {
    position: relative;
    top: 0;
    width: 100%;
    height: 100%;

    @at-root {
      body.desktop {
        .popin-body {
          overflow-y: auto;
        }
      }
      body.tablet, body.mobile {
        .popin-body {
          overflow-y: scroll;
          -webkit-overflow-scrolling: touch;
        }
      }
    }



    padding: 40px 20px;

    .content {
      position: relative;
      width: 100%;
      height: 100%;
    }

  }

  .close {
    position: absolute;
    z-index: 2;
    right: 15px;
    top: 15px;
    width: 29px;
    height: 29px;
    opacity: 1;
    background-color: $colorDarkGray;
    border-radius: 50%;
    border: none;
    font-size: 0;
    transition: all 0.2s;
    &:hover {
      background-color: $colorFuchsia;
    }

  }
  .close:before, .close:after {
    position: absolute;
    top: 7px;
    left: 13px;
    content: ' ';
    height: 16px;
    width: 3px;
    background-color: $colorWhite;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }
}




@include media-breakpoint-down(sm) {
  .popin-wrapper {
      position: fixed;
      width: calc(100% - 20px);
  }
}
body.popin-open {
  #fxb, #app {
    height: 100%;
    max-height: 100%;
    overflow: hidden;
  }
}
