.share-icon {
  position: fixed;
  bottom: 15px;
  left: 15px;
  z-index: 99;

  user-select: none;

  border: none;
  background-color: transparent;

  div {
    width: 48px;
    height: 48px;
    @media(min-width: 481px) {
      width: 64px;
      height: 64px;
    }
    @media(min-width: 1200px) {
      width: 84px;
      height: 84px;
    }
    border-radius: 50%;
    background-color: $colorBeige;
    display: flex;
    align-content: center;
    align-items: center;
    text-align: center;
    box-shadow: 0px 0px 15px -8px $colorDarkGray;
    transform: scale(1);
    transition: transform 0.2s;
  }
  &:hover {
    div {
      transform: scale(1.1);
    }
    span {
      opacity: 1;
    }
  }
  span {
    position: absolute;
    top: -40px;
    left: 0;
    background-color: $colorBeige;
    font-size: 0.9rem;
    white-space: nowrap;
    border-radius: 5px;
    padding: 0.15em 0.75em;
    box-shadow: 0px 0px 15px -8px $colorDarkGray;
    color: $colorDarkGray;

    opacity: 0;
    transition: all 0.2s;
    pointer-events: none;

  }
  svg {

    //fill: $colorDarkGray;
    path {
      fill: $colorFuchsia;
    }

    width: 34px;
    height: 34px;
    @media(min-width: 481px) {
      width: 45px;
      height: 45px;
    }
    @media(min-width: 1200px) {
      width: 65px;
      height: 65px;
    }

    display: inline-block;
    margin: 0 auto;
  }
}