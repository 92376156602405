@keyframes thankyouAppear {
  0% {
    opacity: 0;
    transform: translateX(0) translateY(-90%) scale(0.95);
  }
  100% {
    opacity: 1;
    transform: translateX(0) translateY(-50%) scale(1);
  }
}

@keyframes thankyouDisappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


.thankyou {
  width: calc(100% - 20px);
  max-width: 680px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  //margin-top: 50px;
  z-index: 2;

  display: flex;
  align-items: flex-end;

  &-appear {
    opacity: 0;
    animation-name: thankyouAppear;
    animation-duration: 0.4s;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    &:last-child {
      animation-delay: 0.25s;
    }
  }

  .balloon {
    transform: scaleX(-1) !important;
    div {
      padding: 5% 20px;
      transform: scaleX(-1) !important;
    }

    &:before {
      left: 12px;
      top: 12px;
      width: calc(100% - 24px);
    }
  }
  p {
    font-size: 1rem;
    strong {
      font-size: 1.2rem;
      line-height: 1.1em;
      display: block;
      margin-bottom: 10px;
    }
    a {
      color: $colorFuchsia;
      text-decoration: none;
    }
  }

  .thankyou-logo {
    width: 64px;
    min-width: 64px;
    height: 64px;
    //background-color: lighten($colorFuchsia, 30);
    background-color: $colorBeige;
    border-radius: 50%;
    box-shadow: 0px 0px 15px -8px $colorDarkGray;
    text-align: center;
    line-height: 64px;
    margin-right: 20px;
    transform: translateY(10px);
    img {
      width: 42px;
      height: auto;
    }
  }


  @media (max-width: 400px) {
    margin-left: -10px;
    .balloon {
      div {
        padding: 15px 15px;
        p {
          padding: 0;
          strong {
            font-size: 16px;
          }
        }
      }
    }

  }
}