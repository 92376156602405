@import "../../../scss/variables";

.section.wall {
  width: 100%;
  //height: 100vh;
  //min-height: 300px;
  //overflow: hidden;
  color: $colorWhite;
  text-align: center;
  background: $colorBeige no-repeat 50% 50%;
  background-size: cover;
  //padding: 2% 2% 7% 2%;
  padding: 2%;
  position: relative;
  user-select: none;
  overflow-x: hidden;
  .wall-inner {
    background: transparent no-repeat center bottom;

    //@media(max-width: 641px) {
    //  background: transparent url('/img/wall-bg-xs.jpg') no-repeat center bottom;
    //}
    background-size: cover;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
  }


  .wall-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;
    width: 100%;

    color: $colorDarkGray;
    h2 {
      font-family: $font-family-serif;
      text-transform: uppercase;
      font-size: 2vw;
      strong {
        color: $colorFuchsia;
      }
    }
    p {
      font-family: $font-family-serif;
      font-style: italic;
      font-size: 1.1vw;
      a {
        color: $colorFuchsia;
      }
    }
  }
}