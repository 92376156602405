$colorLightGray: #f5f5f5;
$colorMediumGray: #9c9c9c;
$colorDarkGray: #231f16;
$colorBlack: #000;
$colorWhite: #fff;
$colorFuchsia: #eb008b;
$colorBeige: #efecdc;
//$colorBrown: #C46B1B;
$colorBrown: $colorFuchsia;

$colorLightBlue: #90d5f5;
$colorLightGreen: #81b666;


$font-family-sans-serif: 'Helvetica', 'Roboto', 'Segoe UI', 'Arial', sans-serif;
$font-family-base: $font-family-sans-serif;
$font-family-serif: 'Libre Baskerville', serif;


