.topics-popin {
  max-width: calc(100% - 40px);
  h3 {
    font-size: 1rem;
    margin: 0;
    color: $colorFuchsia;
  }
  p {
    font-size: 12px;
  }
}