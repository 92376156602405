@import "../../../../scss/variables";

@keyframes pointAppear {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.map {
  position: relative;
  user-select: none;
  .point {
    position: absolute;
    width: 100px;
    height: 100px;

    margin: -50px 0 0 -50px;
    user-select: none;
    cursor: pointer;

    .p {
      transform: scale(5);
      opacity: 0;
      animation-name: pointAppear;
      animation-duration: 0.5s;
      animation-delay: 0s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
      background-color: $colorFuchsia;
      border-radius: 50%;
      width: 100%;
      height: 100%;
    }

    span.count {
      display: block;
      color: $colorWhite;
      text-align: center;
      font-size: 80px;
      pointer-events: none;
      user-select: none;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      line-height: 100px;
    }
    span.details {
      display: block;
      color: $colorWhite;
      text-align: center;
      font-size: 1.3rem;
      pointer-events: none;
      user-select: none;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      padding-top: 10px;
    }
    transition: all 0.2s;
    z-index: 1;

    //&:hover {
    //  width: 250px;
    //  height: 80px;
    //  border-radius: 25px;
    //  margin: -40px 0 0 -125px;
    //  transform: scale(1) !important;
    //  z-index: 2;
    //  span.count {
    //    opacity: 0;
    //  }
    //  span.details {
    //    opacity: 1;
    //  }
    //}

  }
}