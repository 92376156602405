.campaigns {

  display: block;

  //@media (min-width: 768px) and (min-height: 768px) {
  //  display: block;
  //}

  margin: -10px auto 0 auto;
  width: 100%;
  max-width: 900px;

  @media (max-width: 767px) {
    margin: 40px auto;
  }

  >div {
    width: calc(100% + 20px);
    margin: 0 -10px;
    display: flex;
    flex-wrap: wrap;

    >div.col {
      width: 25%;
      padding: 10px;

      @media (max-width: 767px) {
        padding: 2px;
      }

      @media (max-width: 480px) {
        width: 50%;
      }

      //&:nth-child(1) {
      //  img:hover {
      //    transform: rotate(-3deg);
      //  }
      //}
      //&:nth-child(2) {
      //  img:hover {
      //    transform: rotate(6deg);
      //  }
      //}
      //&:nth-child(3) {
      //  img:hover {
      //    transform: rotate(-8deg);
      //  }
      //}
      //&:nth-child(4) {
      //  img:hover {
      //    transform: rotate(4deg);
      //  }
      //}

      >div.img {
        width: 100%;
        img {
          width: 100%;
          height: auto;
          cursor: zoom-in;
          box-shadow: 0px 2px 20px -10px $colorDarkGray;
          transition: transform 0.2s;
          //transform: rotate(0deg);
          &:hover {
            //opacity: 0.85;
            transform: scale(1.05) !important;
          }


          &.fullscreen {
            position: fixed;
            width: 100%;
            height: auto;
          }

        }
      }

    }
  }




}



.zoom {

  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  text-align: center;
  z-index: 10;
  cursor: zoom-out;
  //background-color: rgba($colorDarkGray, 0.9);
  padding: 10px;
//visibility: hidden;

  img {
    z-index: 11;
    display: inline-block;
    visibility: visible;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    user-select: none;
    pointer-events: none;
    box-shadow: 0px 5px 1000px 10px $colorDarkGray;
    &.horizontal {
      max-width: 1528px;
      width: 100%;
      height: auto;
      @media(max-width: 1528px) {
        max-width: 100%;
        width: 100%;
        height: auto;
      }
    }
    &.vertical {
      height: 100%;
      max-height: 1080px;
      width: auto;

      @media(max-width: 900px) {
        width: 100%;
        height: auto;
        max-height: none;
      }
      //  max-width: 100%;
      //  width: 100%;
      //  height: auto;
      //  max-height: auto;
      //}

    }
  }
}


.zoomtrans-enter {
  opacity: 0;
  transform: scale(0.9);
}
.zoomtrans-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.zoomtrans-exit {
  opacity: 1;
}
.zoomtrans-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
