@import "scss/variables";
@import "node_modules/bootstrap/scss/bootstrap-reboot";

body {
  background: $colorBeige;
}
html {
  font-size: 13px;
  @media (min-width: 480px) {
    font-size: 14px;
  }
  @media (min-width: 768px) {
    font-size: 15px;
  }
  @media (min-width: 1024px) {
    font-size: 16px;
  }


  scroll-behavior: smooth;

}

@import "src/app/components/NeedHelp/NeedHelp";
@import "src/app/components/ShareIcon/ShareIcon";
@import "src/app/components/Balloon/Balloon";
@import "src/app/components/InfoBoxes/InfoBoxes";
@import "src/app/components/Campaigns/Campaigns";
@import "src/app/components/Footer/Footer";
@import "src/app/components/Popin/Popin";
@import "src/app/components/SharePopin/SharePopin";
@import "src/app/components/TopicsHelp/TopicsHelp";
@import "src/app/components/FormErrors/FormErrors";
@import "src/app/components/ThankYouMessage/ThankYouMessage";
@import "src/app/components/Sections/Home/Home";
@import "src/app/components/Sections/Form/Form";
@import "src/app/components/Sections/Wall/Wall";
@import "src/app/components/Sections/Stats/Stats";
@import "src/app/components/Sections/WhoWeAre/WhoWeAre";
@import "src/app/components/DonationPopin/DonationPopin";

@media (pointer: fine) {
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px rgba(0,0,0,0.2);
    border-radius: 0;
    background-color: $colorBeige;
  }

  ::-webkit-scrollbar {
    width: 0.5rem;
    background-color: $colorBeige;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0;
    // box-shadow: inset 0 0 3px rgba(0,0,0,.3);
    background-color: $colorMediumGray;
  }
}

html, body, #fxb, #app {
  min-height: 100%;
  height: auto;
  width: 100vw;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

#app {

  section {
    //height: auto;
    //min-height: 100vh;
    position: relative;
    .section {
      height: 100%;
      min-height: 100%;
      position: relative;
      box-shadow: 0px 0px 10px -8px $colorDarkGray;
    }
  }

}



#iubenda-cs-banner {
  background-color: $colorFuchsia !important;
  >div {
    padding: 10px !important;
  }
  .iubenda-cs-rationale {
    max-width: 100% !important;
  }
  * {
    background-color: $colorFuchsia !important;
    font-size: 12px !important;
  }
  .iubenda-banner-content {
    p {
      &:first-child {
        display: none;
      }
      margin: 0;
    }
  }

}