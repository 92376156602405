@import "../../scss/variables";

@keyframes balloonAppear {
  0% {
    opacity: 0;
    transform: translateX(-50%) translateY(-90%) scale(0.95);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%) translateY(-50%) scale(1);
  }
}
@keyframes balloonDisappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes tickAppear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.8;
  }
}

.balloon-wrapper {
  position: absolute;
  pointer-events: none;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.2);
    z-index: 2;
  }
  .balloon {
    transition: all 0.2s;
    cursor: pointer;
    max-width: 300px;

  }

}

.balloon {

  position: relative;
  pointer-events: all;
  transform: translateX(-50%) translateY(-50%);
  min-width: 200px;
  min-height: 60px;
  border-radius: 10px 10px 0 10px;
  padding: 10px;
  color: $colorDarkGray;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 15px -8px $colorDarkGray;

  background: $colorLightBlue;

  p {
    z-index: 2;
    position: relative;
    text-align: left;
    padding: 3px 15px 0 15px;
    margin: 0;
    user-select: none;
    font-size: 1rem;
  }

  &:before {
    content: '';
    position: absolute;
    left: 6px;
    top: 6px;
    background: #fff;
    border-radius: 7px;
    width: calc(100% - 12px);
    height: 16px;
    background: linear-gradient(lighten($colorLightBlue, 10), lighten($colorLightBlue, 3));
    opacity: 0.8;
    z-index: -1;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 100%;
    width: 0;
    height: 0;
    border: 16px solid transparent;
    border-left-color: $colorLightBlue;
    border-right: 0;
    border-bottom: 0;
    margin-top: -16px;
    margin-right: -15px;
    z-index: 1;
  }

  &.balloon-blue {
    background: $colorLightBlue;
    &:before {
      background: linear-gradient(lighten($colorLightBlue, 10), lighten($colorLightBlue, 3));
    }
    &:after {
      border-left-color: $colorLightBlue;
    }
  }

  &.balloon-green {
    background: $colorLightGreen;
    &:before {
      background: linear-gradient(lighten($colorLightGreen, 10), lighten($colorLightGreen, 3));
    }
    &:after {
      border-left-color: $colorLightGreen;
    }
  }
  &.balloon-fuchsia {
    background: $colorFuchsia;
    p { color: $colorWhite; }
    &:before {
      background: linear-gradient(lighten($colorFuchsia, 3), lighten($colorFuchsia, 0.5));
    }
    &:after {
      border-left-color: $colorFuchsia;
    }
  }

  &.balloon-appear {
    opacity: 0;
    animation-name: balloonAppear;
    animation-duration: 0.4s;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  &.balloon-disappear {
    opacity: 0;
    animation-name: balloonDisappear;
    animation-duration: 0.4s;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  &.balloon-delay {
    animation-delay: 2s;
  }

  svg {
    width: 14px;
    height: 14px;
    position: absolute;
    right: 8px;
    bottom: 5px;
    opacity: 0;
    animation-name: tickAppear;
    animation-duration: 0.2s;
    animation-delay: 1.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    fill: darken($colorLightBlue,50);
  }



}



