.section.stats {

  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  user-select: none;
  position: relative;

  .paper {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: rotate(180deg) scaleX(-1);
    background: transparent no-repeat center bottom;
    background-size: cover;
  }

  h2 {
    text-transform: uppercase;
    @media (min-width: 768px) {
      margin-left: 50%;
    }
  }


  >div {
    margin: 0 auto;
    width: 100%;
    max-width: 1920px;
    padding: 0 20px;
    z-index: 2;
  }

  @media (min-width: 768px) {
    .row {
      display: flex;
    }
    .col {
      width: 50%;
    }
  }

  img.italy {
    width: 100%;
    max-width: 100%;
    height: auto;
    pointer-events: none;
  }

  @import "Ages/Ages";
  @import "Areas/Areas";
  @import "Genders/Genders";
  @import "Map/Map";

}