.section.who-we-are {
  padding: 40px 20px;
  width: 100%;
  //min-height: 100vh;

  display: flex;
  align-items: center;
  user-select: none;
  position: relative;
  background: $colorBeige no-repeat center bottom;
  background-size: cover;

  @media (max-width: 400px) {
    padding: 0;
  }


  >div {
    z-index: 2;
    //margin: -40px auto 0 auto;
    margin: 0 auto;
    padding: 30px;
    width: 100%;

    @media (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      align-content: space-evenly;
      text-align: center;
      justify-content: center;
      flex-direction: row;
      height: 100%;
      position: relative;
      min-height: 100vh;
      padding: 5% 0;
      max-width: 1000px;
    }

    @media (max-width: 767px) {
      margin: 40px auto;
    }
  }

  div.content {
    z-index: 3;
    margin: -40px auto 0 auto;
    @media (max-width: 767px) {
      margin: 0 auto;
    }
    padding: 30px;
    width: 100%;
    max-width: 900px;
    background-color: $colorLightGray;
    color: $colorMediumGray;
    box-shadow: 0px 0px 15px -10px $colorDarkGray;

    border-radius: 20px;

    @media (max-width: 767px) {
      padding: 15px;
    }

    //@media(min-width: 641px) {
    //  display: flex;
    //}

    .img {
      text-align: center;
      padding: 0 15px;
      margin: 0 0 30px 0;
      @media(min-width: 641px) {
        flex: 0 0 30%;
      }
      img {
        width: 100%;
        max-width: 75px;
        height: auto;
        display: inline-block;
        @media(min-width: 481px) {
          max-width: 109px;
        }
        @media(max-width: 767px) and (max-height: 767px) {
          max-width: 64px;
        }
      }
    }
    .txt {
      text-align: center;
      padding: 20px 0 0 0;
      line-height: 1.4;
      max-width: 990px;
      margin: 10px auto;
      @media(min-width: 641px) {
        //text-align: left;
        padding: 0;
      }
      @media(max-width: 767px) and (max-height: 767px) {
        padding: 0;
      }
      p {
        margin: 0;
        padding: 0;
        @media(max-width: 767px) and (max-height: 767px) {
          font-size: 10px;
        }
        a {
          color: inherit;
          transition: all 0.2s;
          &:hover {
            color: $colorFuchsia;
          }
        }
      }
    }

  }

  .share-button {
    background-color: $colorFuchsia;
    border-radius: 20px;
    border: none;
    color: $colorWhite;
    padding: 20px;
    transition: all 0.2s;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    font-size: 0.9rem;
    margin: 20px auto 50px auto;
    display: inline-block;
    @media(max-width: 640px) {
      max-width: 300px;
    }
    @media (min-width: 768px) {
      &:hover {
        text-decoration: none;
        box-shadow: 0px 0px 20px -5px $colorFuchsia;
        transform: scale(1.05);
      }
    }
  }
}